<template>
  <v-bottom-nav
    :active.sync="bottomNav"
    :value="true"
    app
  >
    <v-btn data-cy="history" value="history" flat color="primary" @click="$router.push(util.path.research.TAB_HISTORY).catch(()=>{});">
      <span>{{ $t('tab.historico.titulo') }}</span>
      <v-icon>face</v-icon>
    </v-btn>

    <!-- <v-btn data-cy="questionnaire" value="questionnaire" flat color="primary" @click="$router.push(util.path.research.TAB_QUESTIONNAIRE).catch(()=>{});">
      <span>{{ $t('tab.questionario.titulo') }}</span>
      <v-icon>assignment</v-icon>
    </v-btn> -->

    <v-btn data-cy="codigos" value="codes" flat color="primary" @click="$router.push(util.path.research.TAB_CODES).catch(()=>{});">
      <span>{{ $t('tab.codigos.titulo') }}</span>
      <v-icon>vpn_key</v-icon>
    </v-btn>

    <v-btn data-cy="settings" value="settings" flat color="primary" @click="$router.push(util.path.research.TAB_SETTINGS).catch(()=>{});">
      <span>{{ $t('tab.configuracoes.titulo') }}</span>
      <v-icon>settings</v-icon>
    </v-btn>
  </v-bottom-nav>
</template>

<script>
import util from '@/router/util'

export default {
  name: 'Navigation',
  data: () => {
    return {
      bottomNav: 'history',
      util: util
    }
  },
  mounted () {
    this.bottomNav = this.$route.path.replace('/research/tab/', '')
  }
}
</script>
