<template>
  <v-layout fill-height>
    <logo-toolbar />
    <router-view />
    <Navigation />
  </v-layout>
</template>

<script>
import LogoToolbar from '@/components/toolbar/LogoToolbar'
import Navigation from './Navigation'

export default {
  name: 'Menu',
  components: {
    LogoToolbar,
    Navigation
  }
}
</script>
