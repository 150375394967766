<template>
  <v-toolbar color="primary" app dark flat>
    <v-layout fill-height align-center justify-center>
      <v-img id="logo" :src="require('@/assets/logo_white.png')" alt="Logo" max-width="40" max-height="40"></v-img>
      <div v-if="getUserData.displayName" class="subheading absolute-right">{{ $t('saudacao') + ', ' + getUserData.displayName }}</div>
    </v-layout>
  </v-toolbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LogoToolbar',
  computed: {
    ...mapGetters(['getUserData'])
  }
}
</script>

<style scoped>
.absolute-right {
  position: absolute;
  right: 1em;
}
</style>
